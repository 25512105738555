import React from 'react';

import cx from 'classnames';

import { BodySmall } from '@clubspark-react/clubspark-react-tools';

import * as styles from './chip.module.less';

export interface ChipProps {
  status: string;
  label: React.ReactNode;
  labelClassName?: string;
}

const Chip: React.FC<ChipProps> = ({ status, label, labelClassName }) => {
  return (
    <div className={styles.root}>
      <div
        className={cx(styles.dot, {
          [styles.statusGreen]: status === 'green',
          [styles.statusRed]: status === 'red',
          [styles.statusYellow]: status === 'yellow',
          [styles.statusBlue]: status === 'blue',
          [styles.statusViolet]: status === 'violet',
        })}
      />
      <BodySmall classnames={labelClassName}>{label}</BodySmall>
    </div>
  );
};

export default Chip;
