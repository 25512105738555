import graphql from 'graphql-tag';

export const GET_PAYOUTS = graphql`
  query GetPayouts {
    payouts {
      id
      amount
      currency
      created
      arrivalDate
      status
    }
  }
`;

export const GET_VENUE_DEFAULT_ACCOUNT = graphql`
  query GetVenueDefaultAccount($venueId: String!) {
    venueDefaultAccount(venueId: $venueId) {
      venueId
      payouts {
        id
        amount
        arrivalDate
        status
      }
    }
  }
`;
